import { getCurrentSelectedOrganizationId } from './loginUtils';

import {
    sendGetRequest,
    processResponse,
    sendPostRequest,
    sendPatchRequest,
    sendDeleteRequest,
    // sendPatchRequest,
    // sendDeleteRequest,
} from './dataRequestUtils';

const ignoreErrorCodes = [404];

/**
 * Get all instances
 */
export const getOrganizationAppInstances = ({
    pageNo = 1,
    searchText,
    sorts = { name: 1 },
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `organization/${getCurrentSelectedOrganizationId()}/app/instances`,
        data: { pageNo, searchText, sorts: JSON.stringify(sorts) },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
            errorMessage: 'Unable to fetch app instances',
        }),
    });

/**
 * Get app instance by instance id
 */
export const getOrganizationAppInstanceById = ({ instanceId, successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `organization/${getCurrentSelectedOrganizationId()}/app/instances`,
        data: { instanceId },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch app instance',
        }),
    });

/**
 * Get all applications
 */
export const getApplicationList = ({ successCallback, errorCallback, finallyCallback } = {}) =>
    sendGetRequest({
        url: `/apps?includePublicApps=true`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch applications list',
        }),
    });

/**
 * Get application interface by id
 */
export const getApplicationInterfaceById = ({
    appId,
    interfaceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) => {
    sendGetRequest({
        url: `/app/${appId}/interface/${interfaceId}`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to fetch application interface by id',
        }),
    });
};

/**
 *
 * @param data
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const createApplicationInstance = ({ data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPostRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${data.appId}/instance`,
        data: { instance: data },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add application instance',
            successMessage: 'Successfully created application instance',
        }),
    });

export const updateApplicationInstance = ({ data, successCallback, errorCallback, finallyCallback } = {}) =>
    sendPatchRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${data.appId}/instance/${data._id}`,
        data: { update: data },
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update application instance',
            successMessage: 'Successfully updated application instance',
        }),
    });

export const deleteApplicationInstance = ({
    instanceId,
    appId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendPatchRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${appId}/instance/${instanceId}/markDeleted`,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to delete application instance',
            successMessage: 'Successfully deleted application instance',
        }),
    });

/**
 * Create app instance webhook
 * @param data
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const createAppInstanceWebhook = ({
    data,
    appId,
    instanceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendPostRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${appId}/instance/${instanceId}/webhook`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to add webhook',
            successMessage: 'Webhook added successfully',
        }),
    });

/**
 * Update app instance webhook
 * @param data
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const updateAppInstanceWebhook = ({
    data,
    appId,
    webhookId,
    instanceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendPatchRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${appId}/instance/${instanceId}/webhook/${webhookId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to update webhook',
            successMessage: 'Webhook updated successfully',
        }),
    });

/**
 * Get app instance webhook by ID
 * @param data
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const getAppInstanceWebhookById = ({
    data,
    appId,
    webhookId,
    instanceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${appId}/instance/${instanceId}/webhook/${webhookId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
        }),
    });

/**
 * Get app instance webhooks
 * @param data
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const getAppInstanceWebhooks = ({
    data,
    appId,
    instanceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendGetRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${appId}/instance/${instanceId}/webhooks`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            ignoreErrorCodes,
        }),
    });

/**
 * Delete app instance webhook by ID
 * @param data
 * @param successCallback
 * @param errorCallback
 * @param finallyCallback
 * @returns {Promise<*>}
 */
export const deleteAppInstanceWebhookById = ({
    data,
    appId,
    webhookId,
    instanceId,
    successCallback,
    errorCallback,
    finallyCallback,
} = {}) =>
    sendDeleteRequest({
        url: `/organization/${getCurrentSelectedOrganizationId()}/app/${appId}/instance/${instanceId}/webhook/${webhookId}`,
        data,
        ...processResponse({
            successCallback,
            errorCallback,
            finallyCallback,
            errorMessage: 'Unable to delete webhook',
            successMessage: 'Webhook deleted successfully',
        }),
    });

/**
 * @returns {Promise<*>}
 */
export const getAppSandboxes = () => {
    return sendGetRequest({
        url: `/appSandbox`,
        successCallback: (response) => response.data,
        errorMessage: 'Unable to retrieve sandboxes',
    });
};

/**
 * @param {string} organizationId
 * @param {Object} sandboxDefinition
 * @param {function} successCallback
 * @param {function} errorCallback
 * @returns {Promise<*>}
 */
export const initializeNewInstanceFromSandbox = async (
    organizationId,
    sandboxDefinition,
    successCallback,
    errorCallback,
) => {
    return sendPostRequest({
        url: `organization/${organizationId}/app/sandbox/${sandboxDefinition._id}`,
        successCallback,
        errorCallback,
        errorMessage: 'Unable to initialize the new sandbox instance',
    });
};
