import { Container, Typography } from '@mui/material';

// Store is needed here to handle async load time race condition
// since the LoadingSCreenContainer is not using code splitting,
// it must be loaded early.
import store from '../../../redux/store';

import useStyles from '../../../styling/jss';
import animation from '../../../assets/images/animationLoading.gif';
import intelyConnectText from '../../../assets/images/intelyConnectText.png';

import {
    getUserLogin,
    restoreUserSessionStates,
    redirectUserTokenExpired,
    updateUserLastRefreshTime,
    updateUserLastActivityTime,
    setupAuthUserDataWithStore,
    getDefaultOrganizationIdUrlParameter,
    getCurrentSelectedOrganizationId,
} from '../../../utils/loginUtils';
import { prepareAppDataGlobally } from '../../../hooks/usePrepareAppDataGloballyHook';

const LoadingScreenContainer = () => {
    const classes = useStyles()();

    window.onload = async function () {
        const loginResults = await getUserLogin();

        if (loginResults !== false) {
            await setupAuthUserDataWithStore(store, loginResults);

            const defaultOrganizationId = getDefaultOrganizationIdUrlParameter() || getCurrentSelectedOrganizationId();
            if (defaultOrganizationId) {
                localStorage.setItem('currentOrganizationId', defaultOrganizationId);
            } else {
                localStorage.setItem('currentOrganizationId', loginResults.defaultOrganizationId);
            }

            prepareAppDataGlobally();

            updateUserLastRefreshTime();
            updateUserLastActivityTime();
            restoreUserSessionStates();
        } else {
            redirectUserTokenExpired();
        }
    };

    return (
        <Container
            component="main"
            maxWidth="xs"
            style={{
                height: '-webkit-fill-available',
                display: 'flex',
                alignItems: 'center',
                overflow: 'auto',
                marginTop: '4rem',
                padding: '0 10px',
                justifyContent: null,
            }}
        >
            <div className={classes.bottomdiv} style={{ wordBreak: 'break-all' }}>
                <img src={animation} alt="img2 Icon" />
                <Typography>
                    <img src={intelyConnectText} style={{ width: '100%' }} alt="connecttext Icon" />
                </Typography>
            </div>
        </Container>
    );
};

export default LoadingScreenContainer;
