import * as constants from './constants';
import { Fragment } from 'react';
import { getStoreState } from '../hooks/useStoreStateHook';
import FunctionParser from '../classes/FunctionParser';
import { getCurrentSelectedOrganizationId } from './loginUtils';
import {
    storeNamespace as mappingStoreNamespace,
    getSelectedInputDataObject,
    getSelectedOutputFieldMappings,
} from '../hooks/useMappingsHook';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

import { showSnackBarErrorNotification } from './snackBarNotificationUtil';
import { getDataTypeByDataTypeId } from '../hooks/useMyDataTypesHook';

/**
 * Get the inputs from the mapping data
 * @param {Array} inputs Mapping inputs
 * @return {String} Mapping inputs
 */
export const getInputsFromMapping = (inputs) => {
    if (!inputs?.length) {
        return '';
    }
    let str = '';
    for (const input of inputs) {
        str += (input.name || '') + ', ';
        if (str.length > 60) {
            return str;
        }
    }
    return str.replace(/, $/, '');
};

/**
 * Get the outputs from the mapping data
 * @param {Array} outputs Mapping outputs
 * @return {String} Mapping outputs
 */
export const getOutputsFromMapping = (outputs) => getInputsFromMapping(outputs);

/**
 * Format the mapping date.
 * @param {String} date
 * @returns {String} Formatted mapping date.
 */
export const formatMappingDate = (date) => (date ? dayjs(date).format('MM/DD/YY HH:mm A') : '-');

/**
 * Filter the root data types from data types list.
 * This is also the resource types.
 * @param {Array<Object>} dataTypes
 * @returns {Array<Object>}
 */
export const filterRootDataTypes = (dataTypes) => dataTypes?.filter((dataType) => dataType?.isRoot);

/**
 * Filter the mapping templates from mappings list
 * @param {Array<Object>} mappings
 * @returns {Array<Object>}
 */
export const filterMappingTemplates = (mappings) =>
    mappings?.filter(
        (mapping) =>
            mapping?.isPublic && mapping?.isTemplate && mapping?.organizationId === getCurrentSelectedOrganizationId(),
    );

/**
 * Categorize the data types
 * @param {Array<object>} dataTypes
 * @returns {Object<Array<object>>}
 */
export const categorizeDataTypes = (dataTypes) => {
    const categories = {};
    for (const dataType of dataTypes) {
        const category = dataType.category;

        if (!category) {
            continue;
        }

        if (!categories[category]) {
            categories[category] = [];
        }

        categories[category].push(dataType);
    }
    return categories;
};

/**
 * Get selected mapping data
 * @returns {String} selected mapping data
 */
export const getSelectedMapping = () => getStoreState('selectedMapping', mappingStoreNamespace)({}) || {};

/**
 * Get selected mapping ID
 * @returns {String} selected mapping ID
 */
export const getSelectedMappingId = () => getSelectedMapping()?._id || '';

/**
 * Get selected mapping inputs
 * @returns {Array<Object>} Selected mapping inputs
 */
export const getSelectedMappingInputs = () => getSelectedMapping()?.inputs || [];

export const getDataTypes = () => getStoreState('dataTypes', mappingStoreNamespace)([]);

/**
 * Get selected mapping outputs
 * @returns {Array<Object>} Selected mapping outputs
 */
export const getSelectedMappingOutputs = () => getSelectedMapping()?.outputs || [];

/**
 * Get mapping data types from given object data type ID.
 * @param {String} objectDataTypeId
 * @param {String} typeObject Data type list to use: 'input' | 'output | '' (all data types)
 * @returns {Array<Object>}
 */
export const getMappingsDataTypesFromObjectByObjectDataTypeId = (objectDataTypeId, typeObject = '') => {
    let dataTypes;
    if (typeObject === 'input') {
        dataTypes = getSelectedMappingInputs();
    } else if (typeObject === 'output') {
        dataTypes = getSelectedMappingOutputs();
    } else {
        dataTypes = getDataTypes();
    }

    return [dataTypes.find((dataType) => dataType?._id === objectDataTypeId)] || [];
};

/**
 * Holds input values in a ref object
 * @param {MutableRefObject} dataRef
 * @param {SyntheticEvent} e
 * @param {String|undefined} value
 */
export const setInputValue = (dataRef, e, value = undefined) => {
    const inputName = e?.target?.name;
    if (inputName && dataRef?.current) {
        dataRef.current[inputName] = value === undefined ? e.target.value : value;
    }
};

/**
 * Get the field mapping occurrences
 * @param {Object} args Field occurrence arguments
 * @param {Object} args.field Field data
 * @param {Object} args.dataType Data type object
 * @param {Integer} args.autoExpandOccurrenceLevel
 * @returns {Array<object>} Field mapping occurrences
 */
export const getFieldMappingOccurrences = ({
    field,
    fields: _fields = null,
    dataType = {},
    occurrenceCounter = 0,
    autoExpandOccurrenceLevel,
    setAutoExpandOccurrenceLevel,
}) => {
    if (_fields) {
        return _fields;
    }

    const occurrences = [],
        isPrimitive = dataType?.isPrimitive,
        fields = isPrimitive ? field.fields || [] : dataType.fields || [],
        fieldId = field?._id,
        fieldName = field?.displayName || field.name,
        isObjectFieldType = dataType?.name === 'object',
        isSingleLevelOccurrenceField = isPrimitive && !isObjectFieldType,
        hasOneOccurrenceLevel = occurrenceCounter < 1 ? 0 : -1;

    // Bail if max cardinality is 1
    if (1 === field.cardinalityMax) {
        return fields;
    }

    // Create the occurrence primitive fields which are not object type.
    let fieldsArray,
        fieldsLen = 0,
        canAppendFieldToOccurrenceField = false;
    if (isSingleLevelOccurrenceField) {
        fieldsArray = [];
        canAppendFieldToOccurrenceField = true;
    } else {
        fieldsArray = fields;
        fieldsLen = fields.length;
    }

    const hasChildren = fieldsLen < 1;

    let counter = -1;
    for (let i = 0; i < occurrenceCounter; i++) {
        let occurrenceData = {};
        const fieldOccurrence = i + 1,
            customFieldName = `${fieldName}[${fieldOccurrence}]`;

        ++counter;

        if (canAppendFieldToOccurrenceField) {
            occurrenceData = {
                ...field,
                // _id: fieldId,
                name: customFieldName,
                displayName: customFieldName,
                cardinalityMax: 1,
                occurrenceIndex: i,
                isCardinalityHidden: true,
                isSingleLevelOccurrenceField: true,

                // For use in `key` attribute to bypass duplicate keys error
                isOccurrenceFieldNode: `${fieldId}@${counter}`,
            };
        } else {
            occurrenceData = {
                // _id: fieldId + '-' + i,
                setAutoExpandOccurrenceLevel,
                _id: fieldId,
                name: customFieldName,
                occurrenceIndex: i,
                occurrenceFields: [],
                isOccurrenceField: true,
                // isExpansionDisabled: isSingleLevelOccurrenceField,
                isExpansionDisabled: hasChildren,
                isOccurrenceFieldNode: `${fieldId}@${counter}`,
                autoExpandOccurrenceLevel: i === autoExpandOccurrenceLevel ? i : hasOneOccurrenceLevel,
            };
        }

        // Determine the last field to show the remove occurrence button
        occurrenceData.isLastOccurrenceField = occurrenceCounter > 1 && occurrenceCounter === fieldOccurrence;

        occurrences.push(occurrenceData);
        const occurrencesLen = occurrences.length;

        if (!canAppendFieldToOccurrenceField) {
            for (const fieldData of fieldsArray) {
                const occurrenceFieldData = {
                    ...fieldData,
                    occurrenceIndex: 0,
                    isExpansionDisabled: isSingleLevelOccurrenceField,
                    parentOccurrenceIndex: i,

                    // For use in `key` attribute to bypass duplicate keys error
                    isOccurrenceFieldNode: `${fieldData._id}@${counter}`,
                    isOutputOccurrenceField: true,
                };

                occurrences[occurrencesLen - 1].occurrenceFields.push(occurrenceFieldData);
            }
        }
    }
    return occurrences;
};

/**
 * Converts array of fieldIds to array of names
 * @param {String} parentDataTypeId
 * @param {Array<String>} path Array of fieldIds
 * @returns {Array<String>} Array of names correlating to fieldIds
 */
const getDataTypeNames = (parentDataTypeId, path) => {
    const dataTypes = getDataTypes();
    let parentDataTypeObj = dataTypes.find((dataType) => dataType._id === parentDataTypeId);
    const pathStrings = [];
    if (!parentDataTypeObj.fields) {
        return ['INVALID REF'];
    }
    for (const fieldId of path) {
        const field = parentDataTypeObj.fields.find(({ _id }) => _id === fieldId);
        if (!field) {
            pathStrings.push('INVALID REF');
            return pathStrings;
        }
        const fieldDataType = dataTypes.find((dataType) => dataType._id === field.dataTypeId);
        if (!fieldDataType) {
            pathStrings.push('INVALID REF');
            return pathStrings;
        }
        pathStrings.push(field.displayName || field.name);
        parentDataTypeObj = fieldDataType;
    }
    return pathStrings;
};

/**
 * Find and return input based on searchType
 * @param {String} searchValue Value used to lookup input
 * @param {String} searchType Type of value used for lookup
 * @returns {Object} Input object
 */
const getInputObject = (searchValue, searchType) => {
    const inputs = getSelectedMappingInputs();
    switch (searchType) {
        case 'name':
            return inputs.find((input) => input.name === searchValue);
        case 'id':
            return inputs.find((input) => input._id === searchValue);
        default:
            return {};
    }
};

/**
 * Translates function expression from ids to text
 * @param {String} functionExpression
 * @returns {String} Translated functionExpression from ids to correlated text or 'INVALID REF' if field does not exist
 */
export const translateFunctionExpression = (functionExpression) => {
    const parser = new FunctionParser(functionExpression);
    const fieldInputs = parser.getFieldInputs();
    const fieldGroups = [];
    const fieldIdGroups = [];

    for (const fieldInput of fieldInputs) {
        fieldIdGroups.push([
            fieldInput.inputId,
            ...fieldInput.inputPath.map((input) => {
                let string = input.dataTypeFieldId;
                if (input.occurrenceIndex > 0) {
                    string += `[${input.occurrenceIndex}]`;
                }
                if (input.fieldIndex) {
                    string += `{${input.fieldIndex}}`;
                }
                return string;
            }),
        ]);

        const inputObject = getInputObject(fieldInput.inputId, 'id');

        if (!inputObject?._id || !inputObject?.dataTypeId) {
            fieldGroups.push(['INVALID INPUT NAME']);
        } else {
            fieldGroups.push([
                inputObject.name,
                ...getDataTypeNames(
                    inputObject.dataTypeId,
                    fieldInput.inputPath.map((path) => path.dataTypeFieldId),
                ),
            ]);
        }
    }

    if (!fieldGroups.length) {
        return functionExpression;
    }

    let translatedString = functionExpression;
    fieldIdGroups.forEach((idGroup, index) => {
        const idString = idGroup.join('.');
        const textString = fieldGroups[index]
            .map((fieldText, fieldIndex) => {
                if (fieldIndex === 0) {
                    return fieldText;
                }
                let string = fieldText;
                const { occurrenceIndex, positionIndex } = fieldInputs[index].inputPath[fieldIndex - 1];
                if (occurrenceIndex > 0) {
                    string += `[${occurrenceIndex}]`;
                }
                if (positionIndex > 0) {
                    string += `{${positionIndex}}`;
                }
                return string;
            })
            .join('.');
        translatedString = translatedString.replace(idString, textString);
    });

    return translatedString;
};

/**
 * Converts array of names to fieldIds
 * @param {String} parentDataTypeId
 * @param {Array<String>} path Array of name strings
 * @returns {Array<String>} Array of fieldIds or 'INVALID REF'/'INVALID INPUT NAME' if field does not exist
 */
const getDataTypeIds = (parentDataTypeId, path) => {
    const dataTypes = getDataTypes();
    let parentDataTypeObj = dataTypes.find((dataType) => dataType._id === parentDataTypeId);
    const pathStrings = [];

    if (!parentDataTypeObj.fields) {
        return ['INVALID INPUT NAME'];
    }

    for (const fieldName of path) {
        const field =
            parentDataTypeObj.fields.length > 0
                ? parentDataTypeObj.fields.find(
                      ({ name, displayName }) => name === fieldName || displayName === fieldName,
                  )
                : dataTypes
                      .find((dataType) => dataType._id === parentDataTypeObj.dataTypeId)
                      ?.fields.find(({ name }) => name === fieldName);

        if (!field) {
            pathStrings.push('INVALID REF');
            return pathStrings;
        }
        const fieldDataType =
            field?.fields && field.fields?.length > 0
                ? field
                : dataTypes.find((dataType) => dataType._id === field.dataTypeId);

        if (!fieldDataType) {
            pathStrings.push('INVALID REF');
            return pathStrings;
        }
        pathStrings.push(field._id);
        parentDataTypeObj = fieldDataType;
    }
    return pathStrings;
};

const getDefaultStringWithInvalidRef = (nameGroup, idGroup) => {
    const invalidRefIndex = idGroup.indexOf('INVALID REF');
    if (invalidRefIndex > -1) {
        return [...nameGroup.slice(0, invalidRefIndex), 'INVALID REF'];
    } else {
        return nameGroup;
    }
};

/**
 * Converts function expression with field names to fieldIds
 * @param {String} functionExpression
 * @returns {String} Function expression with ids correlating to field names
 */
export const convertFunctionExpressionToIds = (functionExpression) => {
    const parser = new FunctionParser(functionExpression);
    const fieldInputs = parser.getFieldInputs();
    const fieldGroups = [];
    const fieldIdGroups = [];

    for (let i = 0; i < fieldInputs.length; i++) {
        fieldGroups.push([
            fieldInputs[i].inputId,
            ...fieldInputs[i].inputPath.map((input) => {
                let string = input.dataTypeFieldId;
                if (input.occurrenceIndex > 0) {
                    string += `[${input.occurrenceIndex}]`;
                }
                if (input.fieldIndex) {
                    string += `{${input.fieldIndex}}`;
                }
                return string;
            }),
        ]);

        const inputObject = getInputObject(fieldInputs[i].inputId, 'name');

        if (!inputObject?._id || !inputObject?.dataTypeId) {
            fieldIdGroups.push(['INVALID INPUT NAME']);
            showSnackBarErrorNotification('Invalid Input name');
        } else {
            fieldIdGroups.push([
                inputObject._id,
                ...getDataTypeIds(
                    inputObject.dataTypeId,
                    fieldInputs[i].inputPath.map((path) => path.dataTypeFieldId),
                ),
            ]);
        }
    }

    if (!fieldIdGroups.length) {
        return functionExpression;
    }

    const hasInvalidRef = fieldIdGroups.filter((idGroup) => idGroup.indexOf('INVALID REF') > -1).length;
    hasInvalidRef && showSnackBarErrorNotification('Invalid path for field function');
    let translatedString = functionExpression;
    fieldGroups.forEach((fieldGroup, index) => {
        const textString = fieldGroup.join('.');
        const idString = hasInvalidRef
            ? getDefaultStringWithInvalidRef(fieldGroup, fieldIdGroups[index]).join('.')
            : fieldIdGroups[index]
                  .map((id, idIndex) => {
                      if (idIndex === 0) {
                          return id;
                      }
                      let string = id;
                      const { occurrenceIndex, positionIndex } = fieldInputs[index].inputPath[idIndex - 1];
                      if (occurrenceIndex > 0) {
                          string += `[${occurrenceIndex}]`;
                      }
                      if (positionIndex > 0) {
                          string += `{${positionIndex}}`;
                      }
                      return string;
                  })
                  .join('.');
        translatedString = translatedString.replace(textString, idString);
    });
    return translatedString;
};

/**
 * Validates syntax of function expression and returns true or false
 * @param {String} functionExpression
 * @returns {Object}
 */
export const isFunctionExpressionValid = (functionExpression) => {
    const parser = new FunctionParser(functionExpression);
    if (parser.errors.length) {
        showSnackBarErrorNotification(parser.errors[0]);
        return {
            isValid: false,
            errors: parser.errors,
        };
    }
    const fieldInputs = parser.getFieldInputs().map((_, index) => ({ inputValue: '', inputId: index }));
    const expressionEvaluation = parser.evalFunction(fieldInputs);
    expressionEvaluation.errors && showSnackBarErrorNotification(expressionEvaluation.errors[0].message);
    return expressionEvaluation.errors ? { isValid: false, errors: expressionEvaluation.errors } : { isValid: true };
};

/**
 * Get the tree view field Id
 * @param {Object} args
 * @param {String} args.fieldId
 * @param {String} args.parentFieldId
 * @param {Boolean} args.hasOccurrence
 * @param {Boolean} args.isInputTree
 * @param {Integer} args.occurrenceIndex
 * @param {Boolean} args.isOccurrenceField
 * @param {String} args.isOccurrenceFieldNode
 * @param {Boolean} args.isPrimitive
 * @return {String} Tree view ID
 */
export const getTreeViewFieldId = ({
    fieldId,
    hasOccurrence,
    occurrenceIndex,
    isInputTree = true,
    parentFieldId = '',
    isOccurrenceField = false,
    isOccurrenceFieldNode = '',
}) => {
    let fieldIdTag;

    if (isInputTree) {
        fieldIdTag = `${fieldId}@0`;
    } else {
        // Is Parent of occurrences
        if (hasOccurrence) {
            fieldIdTag = `${fieldId}@-1`;
        } // Is either a node or a field (i.e. Patient Result[1])
        else if (isOccurrenceField || isOccurrenceFieldNode) {
            fieldIdTag = `${fieldId}@${occurrenceIndex || 0}`;
        } else {
            fieldIdTag = `${fieldId}@0`;
        }
    }

    let treeViewId;
    if (!parentFieldId) {
        treeViewId = fieldIdTag;
    } else {
        treeViewId = `${parentFieldId},${fieldIdTag}`;
    }

    if (!isInputTree) {
        if (parentFieldId?.split(',').pop() === fieldIdTag) {
            if (hasOccurrence) {
                return parentFieldId.substring(0, parentFieldId.length - 2);
            }
            return parentFieldId;
        }
    }

    return treeViewId;
};

/**
 * Get the tree view field ID from the input/output paths
 * @param {String} inputId
 * @param {Array} inputPaths
 * @returns {String} Tree view field ID
 */
export const getTreeViewFieldIdFromPath = (inputId, inputPaths, isInputTree = true) => {
    const paths = [inputId];
    if (isInputTree) {
        for (const pathObject of inputPaths) {
            paths.push(`${pathObject?.dataTypeFieldId || ''}@${pathObject.occurrenceIndex || 0}`);
        }
    } else {
        const dataTypes = getDataTypes();
        const parentObject = getStoreState('selectedOutput', mappingStoreNamespace)({});
        let parentDataType = dataTypes.find((dataType) => dataType._id === parentObject.dataTypeId);
        for (const pathObject of inputPaths) {
            let fieldDataType;
            if (parentDataType.fields?.length) {
                fieldDataType = parentDataType.fields.find(({ _id }) => _id === pathObject.dataTypeFieldId);
            } else {
                const dataType = dataTypes.find(({ _id }) => _id === parentDataType.dataTypeId);
                fieldDataType = dataType.fields.find(({ _id }) => _id === pathObject.dataTypeFieldId);
            }

            const occurrenceIndex = pathObject.occurrenceIndex === null ? '-1' : pathObject.occurrenceIndex;

            if (fieldDataType) {
                parentDataType = fieldDataType;
                if (fieldDataType.cardinalityMax === null && pathObject.occurrenceIndex !== null) {
                    paths.push(
                        `${pathObject?.dataTypeFieldId || ''}@-1`,
                        `${pathObject?.dataTypeFieldId || ''}@${occurrenceIndex}`,
                    );
                } else {
                    paths.push(`${pathObject?.dataTypeFieldId || ''}@${occurrenceIndex}`);
                }
            } else {
                break;
            }
        }
    }
    return paths.join(',');
};

/**
 * Get the highest occurrence from the output paths
 * @param {Array<object>} outputPaths
 * @param {Boolean} canOmitLastMappingField Whether to omit the last field in the mapping object
 * @returns {Integer}
 */
export const getOutputPathsHighestOccurrenceIndex = (outputPaths, canOmitLastMappingField = false) => {
    let highestOccurrenceIndex = 0;
    const _outputPaths = [...outputPaths];

    if (canOmitLastMappingField) {
        _outputPaths.pop();
    }

    for (const pathObject of _outputPaths) {
        if (pathObject.occurrenceIndex > highestOccurrenceIndex) {
            highestOccurrenceIndex = pathObject.occurrenceIndex;
        }
    }
    return highestOccurrenceIndex;
};

/**
 * Get the last output path data
 * @param {Array<object>} outputPaths
 * @returns {Object}}
 */
export const getLastOutputPathData = (outputPaths) => {
    return [...outputPaths].pop();
};

/**
 * Traverse tree
 * @param {Object} args
 */
const traverseTree = ({
    field,
    occurrenceFields,
    outputId = null,
    fieldMappings = [],
    inputPaths = null,
    isInputTree = true,
    outputPaths = null,
    inputFieldsList = null,
    outputFieldsList = null,
    occurrenceIndex = 0,
    currentNestedLevel = 1,
    parentTreeViewFieldId = '',
    pathWithFieldObjects = [],
}) => {
    const fieldId = field?._id,
        dataTypeId = field?.dataTypeId,
        dataType = getMappingsDataTypesFromObjectByObjectDataTypeId(dataTypeId)?.[0] || {};

    const fieldName = field?.displayName || field.name,
        isObjectFieldType = dataType.name === 'object';

    let fieldsList;
    if (isObjectFieldType) {
        fieldsList = field?.fields || [];
    } else {
        if (field?.fields?.length) {
            fieldsList = field.fields;
        } else if (dataType?.fields?.length) {
            fieldsList = dataType.fields;
        } else {
            fieldsList = [];
        }
    }

    const fields = isInputTree ? fieldsList : [];

    const fieldsLen = fields.length,
        isPrimitive = dataType?.isPrimitive,
        isOccurrenceField = field.isOccurrenceField,
        cardinalityMin = field.cardinalityMin,
        cardinalityMax = field?.cardinalityMax,
        hasOccurrence = cardinalityMax === null || cardinalityMax === '*';

    const hasNestedView = fieldsLen > 0 || isOccurrenceField;

    // Tree view field ID. Useful to remove duplicated IDs
    const treeViewFieldId = getTreeViewFieldId({
        isInputTree,
        fieldId,
        isOccurrenceField,
        parentFieldId: parentTreeViewFieldId,
        isPrimitive: isPrimitive && !isObjectFieldType,
        hasOccurrence,
    });

    let occurrenceFieldsList;
    if (fields[0]?.isOccurrenceField) {
        occurrenceFieldsList = fields[0]?.occurrenceFields;
    } else if (isOccurrenceField) {
        occurrenceFieldsList = occurrenceFields;
    } else {
        occurrenceFieldsList = [];
    }

    // const dotStyle = {
    //     position: 'relative',
    //     top: '2px',
    // };

    /**
     * Setup the input/output field cache helper list.
     * Setup input/outputs path cache
     */
    const fieldCacheData = {
        ...field,
        dataType,
        pathWithFieldObjects: [
            ...pathWithFieldObjects,
            {
                displayName: fieldName,
                dataTypeFieldId: fieldId,
                cardinalityMin,
                cardinalityMax,
            },
        ],
    };

    const fieldPath = [
        {
            dataTypeFieldId: fieldId,
            occurrence: occurrenceIndex,
        },
    ];

    if (isInputTree) {
        inputFieldsList && inputFieldsList.set(treeViewFieldId, fieldCacheData);

        if (inputPaths) {
            const fieldPaths = (inputPaths.get(parentTreeViewFieldId) || [])?.concat(fieldPath);
            inputPaths.set(treeViewFieldId, fieldPaths);
        }
    } else {
        outputFieldsList && outputFieldsList.set(treeViewFieldId, fieldCacheData);

        if (outputPaths) {
            const fieldPaths = (outputPaths.get(parentTreeViewFieldId) || [])?.concat(fieldPath);
            outputPaths.set(treeViewFieldId, fieldPaths);
        }
    }

    if (hasNestedView && currentNestedLevel <= constants.MAPPING_TREE_VIEW_NESTING_LIMIT) {
        fields?.forEach((nestedField) =>
            traverseTree({
                outputId,
                inputPaths,
                outputPaths,
                isInputTree,
                fieldMappings,
                inputFieldsList,
                outputFieldsList,
                field: nestedField,
                parentTreeViewFieldId: treeViewFieldId,
                currentNestedLevel: currentNestedLevel + 1,
                occurrenceFields: occurrenceFieldsList,
                pathWithFieldObjects: fieldCacheData.pathWithFieldObjects,
            }),
        );
    }
};

/**
 * Get input paths from tree view
 * @param {Object} args
 * @param {Object} args.mappingData Mapping data to get input paths from
 * @return {Object} Mapping tree view states
 */
export const prepareTreeViewMappingData = async ({ mappingData }) => {
    const inputPaths = new Map(),
        inputFieldsList = new Map(),
        outputPaths = new Map(),
        outputFieldsList = new Map();

    const fieldMappings = mappingData?.fieldMappings || [];

    // Input tree
    const inputs = mappingData?.inputs || [];
    for (const input of inputs) {
        const inputDataType = getMappingsDataTypesFromObjectByObjectDataTypeId(input?.dataTypeId)?.[0] || {};

        const inputFields = inputDataType?.fields || [];

        inputFields?.forEach((field) =>
            traverseTree({
                field,
                inputPaths,
                fieldMappings,
                inputFieldsList,
                outputFieldsList,
                isInputTree: true,
                parentTreeViewFieldId: input._id,
            }),
        );
    }

    // Custom fields
    const customFields = mappingData?.customFields || [];
    for (const customField of customFields) {
        inputFieldsList.set(getCustomFieldTreeViewId(customField?._id), customField);
    }

    return {
        selectedMappingInputPaths: inputPaths,
        selectedMappingOutputPaths: outputPaths,
        selectedMappingInputFields: inputFieldsList,
        selectedMappingOutputFields: outputFieldsList,
    };
};

/**
 * Get the custom field tree view ID
 * @param {String} customFieldId
 * @return {String} Tree view custom field ID
 */
export const getCustomFieldTreeViewId = (customFieldId) => {
    if (isTreeViewFieldIdCustomField(customFieldId)) {
        return customFieldId;
    }
    return `customField-${customFieldId}`;
};

/**
 * Check whether the tree view is a custom field
 * @param {String} treeViewFieldId
 * @return {Boolean}
 */
export const isTreeViewFieldIdCustomField = (treeViewFieldId) => 'customField' === treeViewFieldId?.split('-')?.[0];

/**
 * Get the custom field ID from tree view ID
 * @param {String} treeViewFieldId
 * @return {String} Custom field ID
 */
export const getCustomFieldIdFromTreeViewId = (treeViewFieldId) => treeViewFieldId?.split('-')?.[1] || treeViewFieldId;

/**
 * Check whether the custom field table is active
 * @return {Boolean}
 */
export const isCustomFieldsTableActive = () => getSelectedInputDataObject()?._id === 'custom';

/**
 * Returns path array with additional data from field objects
 * @param {Array<Object>} path
 * @returns {Array<Object>}
 */
export const getOutputPathFieldObjects = (path = []) => {
    const dataTypes = getDataTypes();
    const pathObjects = [];
    const parentObject = getStoreState('selectedOutput', mappingStoreNamespace)({});
    let parentDataType = dataTypes.find((dataType) => dataType._id === parentObject.dataTypeId);

    if (!parentDataType) {
        return [];
    }

    for (const field of path) {
        let fieldDataType;

        if (parentDataType.fields?.length) {
            fieldDataType = parentDataType.fields.find(({ _id }) => _id === field.dataTypeFieldId);
        } else {
            const dataType = dataTypes.find(({ _id }) => _id === parentDataType.dataTypeId);
            fieldDataType = dataType.fields.find(({ _id }) => _id === field.dataTypeFieldId);
        }

        if (fieldDataType) {
            pathObjects.push({
                ...field,
                displayName: fieldDataType.displayName || fieldDataType.name || '',
                cardinalityMin: fieldDataType.cardinalityMin,
                cardinalityMax: fieldDataType.cardinalityMax,
            });
            parentDataType = fieldDataType;
        } else {
            return [];
        }
    }

    return pathObjects;
};

/**
 *
 * @param {Object} fieldMapping
 * @returns {Object|null}
 */
export const getMappedInputFieldObjectForFieldMapping = (fieldMapping = {}) => {
    if (!fieldMapping.outputPath?.length) {
        return null;
    }

    const inputs = getSelectedMappingInputs();
    const dataTypes = getDataTypes();

    const inputForFieldMapping = inputs.find((input) => input._id === fieldMapping.inputId);

    if (inputForFieldMapping) {
        let parentDataType = dataTypes.find((dataType) => dataType._id === inputForFieldMapping.dataTypeId),
            lastFieldObject = null;

        const inputStrings = [
            {
                name: inputForFieldMapping.name,
                occurrence: 0,
            },
        ];

        if (parentDataType) {
            for (const path of fieldMapping.inputPath) {
                let fieldDataType;

                if (parentDataType.fields?.length) {
                    fieldDataType = parentDataType.fields.find(({ _id }) => _id === path.dataTypeFieldId);
                } else {
                    const dataType = dataTypes.find(({ _id }) => _id === parentDataType.dataTypeId);
                    fieldDataType = dataType.fields.find(({ _id }) => _id === path.dataTypeFieldId);
                }

                if (fieldDataType) {
                    inputStrings.push({
                        name: fieldDataType.displayName || fieldDataType.name,
                        occurrence: path.occurrenceIndex,
                        cardinalityMax: fieldDataType.cardinalityMax,
                    });

                    lastFieldObject = fieldDataType;
                    parentDataType = fieldDataType;
                }
            }

            return {
                ...lastFieldObject,
                fieldPathString: inputStrings.map(({ name, occurrence, cardinalityMax }, index) => (
                    <Fragment key={index}>
                        {name}
                        {index !== 0 &&
                            (cardinalityMax === null
                                ? `[${occurrence === null ? '*' : parseInt(occurrence) + 1}]`
                                : `[${parseInt(occurrence) + 1}]`)}
                        {index < inputStrings.length - 1 && <span style={{ position: 'relative', top: '2px' }}>•</span>}
                    </Fragment>
                )),
            };
        } else {
            return null;
        }
    } else {
        return null;
    }
};

/**
 * Get data type display name
 * @param {Object} datatype
 * @return {String}
 */
export const getDataTypeDisplayName = (datatype) => datatype?.displayName || datatype?.name || datatype?.label || '';

/**
 * Get unique data type array from data type field array
 * @param {Array} data
 * @return {Array}
 */
export const getUniqueDataTypeIDFromDataTypeFieldArray = (data, uniqueID = new Set()) => {
    data.forEach((item) => {
        uniqueID.add(item.dataTypeId);
        if (item.fields?.length > 0) {
            getUniqueDataTypeIDFromDataTypeFieldArray(item.fields, uniqueID);
        }
    });
    return Array.from(uniqueID);
};

/**
 * Get all dependent data types for the given data types
 * @param {Array} data
 * @return {Array}
 */
export const getDependentDataTypeFieldsDataTypes = (dependentDataTypes, allDependentDataTypes) => {
    dependentDataTypes.forEach((item) => {
        if (!allDependentDataTypes.includes(item)) {
            const dataType = getDataTypeByDataTypeId(item);

            if (dataType.category !== 'Primitive' && dataType?.fields.length > 0) {
                allDependentDataTypes.push(item);
                const uniqueDataTypes = getUniqueDataTypeIDFromDataTypeFieldArray(dataType.fields);

                if (uniqueDataTypes.length > 0) {
                    getDependentDataTypeFieldsDataTypes(uniqueDataTypes, allDependentDataTypes);
                }
            }
        }
    });
    return allDependentDataTypes;
};

/**
 * Checks if a field with max cardinality of null has mapped occurrence fields
 * @param {Object} field
 * @return {{exists: Boolean, mapping: Array<Object>}}
 */
export const arrayFieldHasMappedOccurrences = (field) => {
    const returnObj = {
        exists: false,
        mappings: [],
    };

    const fieldMappings = getSelectedOutputFieldMappings();

    if (fieldMappings.length) {
        const matchedFieldMappings = fieldMappings.filter(
            (fieldMapping) =>
                fieldMapping.outputPath.at(-1).occurrenceIndex !== null &&
                fieldMapping.outputPath.some((path) => path.dataTypeFieldId === field._id),
        );

        if (matchedFieldMappings.length > 0) {
            returnObj.exists = true;
            returnObj.mappings = matchedFieldMappings;
        }
    }

    return returnObj;
};
