import { useState, useEffect } from 'react';

import { getAllDataTypes } from '../utils/rosettaDataRequestUtils';
import { setMappingDataTypes } from './useMappingsHook';
import { setAllDataTypes } from './useMyDataTypesHook';
import { useStoreValue, setStoreState, getStoreState } from './useStoreStateHook';

export const storeNamespace = 'appSchemas';

/**
 * Default states
 */
export const schemaStoreStates = {
    schemas: [],
};

/**
 * Get the schemas and subscribe to changes.
 * @returns {Array<Object>}
 */
export const useSchemasValue = () => useStoreValue('schemas', storeNamespace)([]);

/**
 * Set the schemas.
 * @param {Array<Object>}
 */
export const setSchemas = (schemas) => setStoreState('schemas')(schemas);

/**
 * Set the schemas.
 * @param {Array<Object>}
 */
export const getSchemas = () => getStoreState('schemas', storeNamespace)();

/**
 * Get all data types (schemas) for use in select fields.
 *
 * Note: This is called globally and run once after user has logged in.
 *
 * @param {{ isUserLoggedIn: false|true }} args
 *
 * @return {Array<object>}
 */
export const useSchemas = ({ isUserLoggedIn }) => {
    const [datatypes, setDataTypes] = useState(null),
        schemas = useSchemasValue();

    useEffect(() => {
        async function fetchSchemas() {
            if (isUserLoggedIn && !schemas.length) {
                await getAllDataTypes({
                    pageNo: null,
                    successCallback: (res) => {
                        const { results = [] } = res?.data || {};
                        setSchemas(results);
                        setDataTypes(results);
                        setMappingDataTypes(results);
                    },
                });
            }
        }
        fetchSchemas();

        return () => null;
    }, [isUserLoggedIn]);

    return datatypes || schemas;
};

/**
 * Use data type objects and subscribe to changes
 *
 * @return {Array<object>}
 */
export const useDataTypesObject = () => {
    const schemas = useSchemasValue();

    useEffect(() => {
        const allDataTypes = {};
        schemas.forEach((dataType) => (allDataTypes[dataType._id] = dataType));
        setAllDataTypes(allDataTypes);
    }, [schemas]);
};
